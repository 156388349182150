import React, {useEffect, useState} from "react";
import {config} from "../util/consts";
import axios from "axios";
import {AutoComplete} from "primereact/autocomplete";
import {DropdownDTO} from "../model/Model";

export default function DeviceDropdown(props: {
	selectedDeviceId: string | undefined,
	onSelect: (selectedDevice: DropdownDTO) => void
}) {

	const [selectedDevice, setSelectedDevice] = useState<DropdownDTO>();
	const [devices, setDevices] = useState<DropdownDTO[]>([]);
	const [filteredDevices, setFilteredDevices] = useState<DropdownDTO[]>([]);

	useEffect(() => {
		axios.get<DropdownDTO[]>(config.url.API_URL + "device/dropdown")
			.then(response => {
				setDevices(response.data);
				if (!props.selectedDeviceId)
					return

				let filter = response.data.filter(device => device.value === props.selectedDeviceId)[0];
				setSelectedDevice(filter)
			})
	}, [props.selectedDeviceId])

	const search = (event: any) => {
		if (!event.query.trim().length) {
			setFilteredDevices(devices);
		} else {
			setFilteredDevices(
				devices.filter((device) =>
					event.query.trim().toLowerCase().split(" ")     // splits the words we are quering for into an array "water kwaliteit" becomes ["water", "kwaliteit"]
						.filter((query: string) => device.label.toLowerCase().indexOf(query) === -1) // checks if both array values are present in the device.label; if so don't retain the value
						.length === 0  // check if values are retained, and thus don't match the query.label
				))
		}
	}

	return (
		<AutoComplete placeholder="Select a device"
					  id="selectDevice"
					  field="label"
					  suggestions={filteredDevices}
					  value={selectedDevice}
					  completeMethod={search}
					  dropdown
					  dropdownMode="current"
					  size={75}
					  onChange={(e) => setSelectedDevice(e.value)}
					  onSelect={(e) => props.onSelect(e.value)}
		/>
	);

}
