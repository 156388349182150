import React, {useEffect, useState} from "react";
import {config, formatDate} from "../util/consts";
import axios from "axios";
import ApexCharts, {ApexOptions} from "apexcharts";
import moment from "moment-timezone";
import ReactApexChart from "react-apexcharts";

interface ChartDTO {
	options: ApexOptions,
	series: ApexAxisChartSeries | ApexNonAxisChartSeries
}

export default function DeviceChart(props: {
	deviceId: string | undefined,
	timeRange: (Date | null)[]
}) {

	const [timelineChart, setTimelineChart] = useState<ChartDTO>({
		options: {
			chart: {
				id: 'timeline',
				type: 'area',
				brush: {
					target: 'temperature',
					enabled: true
				},
				selection: {
					enabled: true,
				},
				events: {
					selection(chart: any, options?: any) {
					}
				}
			},
			title: {
				text: 'Timeline',
				style: {
					fontSize: '20px',
					fontWeight: 'bold'
				}
			},
			noData: {
				text: "Loading..."
			},
			colors: ['#008FFB'],
			fill: {
				type: 'gradient',
				gradient: {
					opacityFrom: 0.91,
					opacityTo: 0.1
				}
			},
			xaxis: {
				type: 'datetime',
				tooltip: {
					enabled: false
				}
			},
			yaxis: {
				tickAmount: 2,
				tooltip: {
					enabled: false
				}
			}
		},
		series: []
	})

	const [parameterCharts, setParameterCharts] = useState<ChartDTO[]>([])

	function createGenericChart(id: string, title: string, unit: string, series: ApexAxisChartSeries | ApexNonAxisChartSeries, annotations: XAxisAnnotations[]): ChartDTO {
		return {
			options: {
				chart: {
					id: id,
					type: 'line',
					group: 'telemetry-group'
				},
				title: {
					text: title,
					style: {
						fontSize: '20px',
						fontWeight: 'bold'
					}
				},
				noData: {
					text: "Loading..."
				},
				colors: ["#5B9EF5"],
				legend: {
					show: false
				},
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false
					}
				},
				yaxis: {
					labels: {
						formatter: (value: number) => `${Math.abs(value) < 1e-5 ? 0 : value} ${unit}`,
						minWidth: 40
					}
				},
				annotations: {
					xaxis: annotations
				},
				stroke: {
					width: [5, 10]
				},
				tooltip: {
					x: {
						formatter: (timestamp: number) => `${moment(timestamp).format('DD/MM/YYYY HH:mm [(GMT]ZZ[)]')}`
					},
					y: {
						formatter: (value: number) => `${value} ${unit}`
					}
				}
			},
			series: series
		};
	}

	useEffect(() => {

		if (!(props.deviceId && props.timeRange && props.timeRange[0] && props.timeRange[1]))
			return

		axios.get<any>(config.url.API_URL + "chart/device/" + props.deviceId + "/outliers/" + formatDate(props.timeRange[0]) + "/" + formatDate(props.timeRange[1]))
			.then(response => {

				const annotations = response.data

				axios.get<any>(config.url.API_URL + "chart/device/" + props.deviceId + "/telemetry/" + formatDate(props.timeRange[0]) + "/" + formatDate(props.timeRange[1]))
					.then(response => {

						const telemetry = response.data
						const responseKeys = Object.keys(telemetry)
						const responseContainsValues = responseKeys.length !== 0;

						if (!responseContainsValues)
							return

						setTimelineChart(current => {
							current.series = [telemetry[responseKeys[0]]];
							current.options.chart!.events!.selection = function (chartContext: any, {xaxis, _}: any) {
								let timespan = {
									xaxis: {
										min: xaxis.min,
										max: xaxis.max
									}
								};
								ApexCharts.getChartByID(responseKeys[0])?.updateOptions(timespan);
							}
							return current;
						})

						setParameterCharts(responseKeys.map((key) =>
							createGenericChart(
								key,
								telemetry[key].name,
								telemetry[key].unit,
								[telemetry[key]],
								annotations[telemetry[key].name]
							)
						))

					})
			})

	}, [props.deviceId, props.timeRange])

	if (!props.deviceId || !props.timeRange) {
		return <>This is weird, are you sure you're doing this correctly?</>;
	}

	if (!parameterCharts || parameterCharts.length === 0) {
		return <>No data found for this device</>;
	}

	return (
		<div className="app">
			<div className="row">
				<div className="mixed-chart">
					<ul style={{listStyleType: 'none'}}>
						<li>
							<ReactApexChart
								key={'timeline_chart'}
								type="area"
								options={timelineChart.options}
								series={timelineChart.series}
								height="150"
								width="90%"
							/>
						</li>
						{parameterCharts.map((parameterChart) => (
							<li key={parameterChart.options.chart!.id}>
								<ReactApexChart
									type="line"
									options={parameterChart.options}
									series={parameterChart.series}
									height="300"
									width="90%"
								/>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	)

}
