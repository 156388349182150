import React, {useState} from 'react';
import {config, formatDate} from "../util/consts";
import {useParams, useSearchParams} from "react-router-dom";
import {Calendar} from "primereact/calendar";
import DeviceDropdown from "../components/DeviceDropdown";
import DeviceChart from "../components/DeviceChart";

export default function ChartPage() {

	const {deviceId} = useParams()
	const [searchParams] = useSearchParams();

	const [selectedTimeRange, setSelectedTimeRange] = useState<(Date | null)[]>( // TODO : create a proper Type (from/to)
		[
			searchParams.has("from") ? new Date(searchParams.get("from")!) : new Date(new Date().setDate(new Date().getDate() - 30)),
			searchParams.has("until") ? new Date(searchParams.get("until")!) : new Date()
		]
	);

	return (
		<>
			<div className="flex">
				<div className="flex-1">
					<h1>Graphs</h1>
				</div>
			</div>
			<div className="flex flex-row gap-4 align-items-center">
				<label htmlFor="selectDevice">Select a device</label>
				<DeviceDropdown
					selectedDeviceId={deviceId}
					onSelect={device => {
						redirectToDevice(device.value, selectedTimeRange)
					}}
				/>
				<label htmlFor="selectTimeRange">Select a time range</label>
				<Calendar value={selectedTimeRange}
						  dateFormat="dd/mm/yy" selectionMode="range" readOnlyInput hideOnRangeSelection
						  onChange={(e) => setSelectedTimeRange(e.value!)}
						  onHide={() => redirectToDevice(deviceId!, selectedTimeRange)}
				/>
			</div>
			{deviceId !== undefined // && timelineChart.series?.length! > 0
				? <DeviceChart
					deviceId={deviceId}
					timeRange={selectedTimeRange}
				/>
				: <div>
					Please select a device to chart it's telemetry.
				</div>
			}
		</>
	);
}

function redirectToDevice(deviceId: string, range: (Date | null)[]) {
	if (deviceId && range.length === 2 && range[0] != null && range[1] != null) {
		document.location = config.url.UI_URL + 'graph/' + deviceId + '?from=' + formatDate(range[0]) + '&until=' + formatDate(range[1]);
	}
}

